import Bubbles from 'components/Bubbles'
import { useContext, useEffect, useState } from 'react'
import Card from './components/Card'
import Header from './components/Header'
import PreLoader, { PreLoaderContext } from './components/PreLoader'
import Routes from './routes'

function App() {
  const { show, setShow } = useContext(PreLoaderContext)

  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 1500)
  }, [])

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-gradient-to-br from-gray-400 to-gray-200 overflow-hidden">
        <Bubbles />
      </div>
      <PreLoader />
      <div
        className="
          container
          relative
          flex
          flex-col
          max-w-[25rem]
          pt-24
          transition-all
          z-10

          sm:max-w-[44rem]
          sm:pt-28
          sm:px-4
          sm:pl-32 
          
          lg:max-w-5xl
          lg:h-[90vh]
          lg:pl-4
          lg:flex-row
          lg:flex-nowrap
          lg:mx-auto

          xl:max-w-7xl
        "
      >
        <Header />
        <main className="flex flex-col w-full lg:flex-row">
          <Card />
          <Routes />
        </main>
      </div>
    </>
  )
}

export default App
