import { createContext, FC, useContext, useMemo, useState } from 'react'
import cn from 'classnames'
import { TChildren } from 'types'

interface IContextProps {
  show: boolean
  setShow: (type: boolean) => void
}

type PreLoaderProviderProps = {
  children: TChildren
}

export const PreLoaderContext = createContext({} as IContextProps)

export const PreLoaderProvider = (props: PreLoaderProviderProps) => {
  const [show, setShow] = useState(true)

  const value = useMemo(
    () => ({
      show,
      setShow,
    }),
    [show]
  )

  return (
    <PreLoaderContext.Provider value={value}>
      {props.children}
    </PreLoaderContext.Provider>
  )
}

const PreLoader: FC = (): JSX.Element => {
  const { show } = useContext(PreLoaderContext)

  return (
    <div
      className={cn(
        { flex: show, hidden: !show },
        'fixed top-0 left-0 right-0 bottom-0 justify-center items-center bg-white z-20'
      )}
    >
      <div className="relative">
        <div className="absolute -top-6 -left-6 w-12 h-12 bg-green-900 rounded-full animate-sk-bounce"></div>
        <div className="absolute -top-6 -left-6 w-12 h-12 bg-green-900 rounded-full animate-sk-bounce-delay opacity-60"></div>
      </div>
    </div>
  )
}

export default PreLoader
