import { FC } from 'react'
import cn from 'classnames'

import styles from './Bubbles.module.css'

const Bubbles: FC = (): JSX.Element => {
  return (
    <ul className="absolute top-0 left-0 w-full h-full">
      <li className={styles.Bubble}>ReactJs</li>
      <li className={cn(styles.Bubble, styles.Bubble2)}>HTML+CSS</li>
      <li className={cn(styles.Bubble, styles.Bubble3)}>Front End</li>
      <li className={cn(styles.Bubble, styles.Bubble4)}>JavaScript</li>
      <li className={cn(styles.Bubble, styles.Bubble5)}>UI/UX</li>
      <li className={cn(styles.Bubble, styles.Bubble6)}>Node.js</li>
      <li className={cn(styles.Bubble, styles.Bubble7)}>TypeScript</li>
      <li className={cn(styles.Bubble, styles.Bubble8)}>MERN</li>
      <li className={cn(styles.Bubble, styles.Bubble9)}>Back End</li>
      <li className={cn(styles.Bubble, styles.Bubble10)}>Webpack</li>
    </ul>
  )
}

export default Bubbles
