import { FC } from 'react'
import Headline from 'components/Headline'

const Contacts: FC = (): JSX.Element => {
  return (
    <main>
      <Headline highlight="Get" rest="in Touch" />
      <section>
        <p>
          <strong>Tel:</strong>
        </p>
      </section>
      <Headline highlight="Contact" rest="Form" />
      <section></section>
    </main>
  )
}

export default Contacts
