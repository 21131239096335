import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import styles from './Header.module.css'

const Header: FC = (): JSX.Element => {
  let location = useLocation()

  return (
    <header className={styles.Header}>
      <div className="sm:hidden px-4 py-2">Valerii Cara</div>
      <nav
        className="
          flex
          justify-center
          w-full
          bg-white
            
          sm:flex-col
          sm:bg-transparent
            
          md:mr-4 
        "
      >
        <Link
          className={cn(styles.Link, {
            [styles.LinkActive]: location.pathname === '/',
          })}
          to={'/'}
        >
          <FontAwesomeIcon className={styles.LinkIcon} icon={solid('user')} />
          Home
        </Link>
        <Link
          className={cn(styles.Link, {
            [styles.LinkActive]: location.pathname === '/resume',
          })}
          to={'/resume'}
        >
          <FontAwesomeIcon
            className={styles.LinkIcon}
            icon={solid('clipboard-list')}
          />
          Resume
        </Link>
        <Link
          className={cn(styles.Link, {
            [styles.LinkActive]: location.pathname === '/contacts',
          })}
          to={'/contacts'}
        >
          <FontAwesomeIcon className={styles.LinkIcon} icon={solid('at')} />
          Contacts
        </Link>
      </nav>
    </header>
  )
}

export default Header
