import { FC, ReactEventHandler, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import styles from './Card.module.css'
import { PreLoaderContext } from 'components/PreLoader'
import TypewriterSet from 'components/TypewriterSet'

const Card: FC = (): JSX.Element => {
  const { setShow } = useContext(PreLoaderContext)

  const downloadHandler: ReactEventHandler = async (e) => {
    e.preventDefault()

    setShow(true)

    try {
      const res = await fetch('documents/ValeriiCara-CV.pdf')
      const blob = res.blob()

      blob.then(async (doc) => {
        await new Promise<void>((resolve) =>
          setTimeout(() => {
            setShow(false)
            resolve()
          }, 1000)
        )
        const fileURL = window.URL.createObjectURL(doc)
        const alink = document.createElement('a')

        alink.href = fileURL
        alink.download = 'ValeriiCara-CV.pdf'
        alink.click()
        alink.remove()
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <section className={styles.Card}>
      <div
        className="
          flex
          flex-col
          relative
          w-full
          h-[480px]
          bg-white
          rounded-t
          rounded-b
          z-10

          sm:h-[600px]
          lg:h-full
        "
      >
        <figure
          className="
          relative
            h-[80%]
            rounded
            bg-[url('assets/images/photo.jpg')]
            bg-cover
            bg-top
            overflow-hidden

              before:absolute
              before:-left-[25%]
              before:-bottom-[63%]
              before:w-full
              before:h-[70%]
              before:bg-white
              before:rotate-12
              after:absolute
              after:left-auto
              after:-right-[25%]
              after:-bottom-[63%]
              after:w-full
              after:h-[70%]
              after:bg-white
              after:-rotate-12
              "
        ></figure>
        <div className="hidden sm:flex justify-center pt-4 text-[34px] text-text-secondary">
          Valerii Cara
        </div>
        <TypewriterSet />
        <ul className="flex justify-center pb-4 text-text-secondary">
          <li className="px-1">
            <a
              href="https://github.com/flashz1"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={brands('github')} />
            </a>
          </li>
          <li className="px-1">
            <a
              href="https://stackoverflow.com/users/3734509/flash?tab=profile"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={brands('stack-overflow')} />
            </a>
          </li>
          <li className="px-1">
            <a
              href="https://www.linkedin.com/in/caravalerii?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B4aovmFUOSWKN0UXmvqoDZw%3D%3D"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={brands('linkedin')} />
            </a>
          </li>
        </ul>
        <div className={styles.DownloadCV}>
          <a href="" onClick={downloadHandler}>
            Download CV <FontAwesomeIcon icon={solid('cloud-download')} />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Card
