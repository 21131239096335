import { FC } from 'react'
import Headline from 'components/Headline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const Home: FC = (): JSX.Element => {
  return (
    <main>
      <Headline highlight="About" rest="Me" />
      <section>
        <p>
          <strong>Hello! I'm Valerii Cara.</strong>&nbsp; Frond-end developer
          from Republic of Moldova, Chisinau. I have rich experience in
          front-end development, also I am good at Reactjs. I love to talk with
          you about our unique.
        </p>
      </section>
      <Headline highlight="My" rest="Services" />
      <section>
        <div>
          <FontAwesomeIcon
            className="text-5xl text-green-800"
            icon={brands('html5')}
          />
          <h3>Front-end</h3>
          <p className="py-4">
            Modern and mobile-ready website that will help you reach all of your
            marketing.
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            className="text-5xl text-green-800"
            icon={brands('react')}
          />
          <h3>ReactJs developer</h3>
          <p className="py-4">
            Modern and mobile-ready website that will help you reach all of your
            marketing.
          </p>
        </div>
      </section>
      <Headline highlight="Fun" rest="Facts" />
      <section>
        <div>
          <FontAwesomeIcon
            className="text-3xl text-green-800"
            icon={brands('internet-explorer')}
          />
          <p className="py-4">
            I started to work as a Front End developer when Interner Explorer 7
            was the main bugs diller
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            className="text-3xl text-green-800"
            icon={brands('wordpress')}
          />
          <p className="py-4">
            My first experience in web development was with Wordpress
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            className="text-3xl text-green-800"
            icon={solid('flag')}
          />
          <p className="py-4">
            In 5 years as freelancer I visited 15 countries
          </p>
        </div>
      </section>
    </main>
  )
}

export default Home
