import { FC } from 'react'
import { Route, Routes as Router, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { routesConfig } from './consts'

const Routes: FC = () => {
  const location = useLocation()

  return (
    <section
      className="
        flex
        flex-col
        relative
        py-7
        bg-white
        rounded-t
        rounded-b

        md:ml-0
        
        lg:w-full
        lg:max-w-[480px]
        lg:mt-4
        lg:mb-4
        lg:rounded-tl-none
        lg:rounded-bl-none
        
        xl:max-w-none
      "
    >
      <div className="px-7 md:overflow-hidden md:overflow-y-auto">
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Router location={location}>
              {routesConfig.map((route) => (
                <Route
                  path={route.path}
                  element={<route.component />}
                  key={route.name}
                />
              ))}
            </Router>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </section>
  )
}

export default Routes
