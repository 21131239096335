import { FC } from 'react'

import styles from './TypewriterSet.module.css'

const TypewriterSet: FC = (params): JSX.Element => {
  return (
    <div className="hidden sm:flex justify-center">
      <div className={styles.TypewriterSet}>
        <h3 className={styles.TypewriterSetItem}>Front End Developer</h3>
      </div>
    </div>
  )
}

export default TypewriterSet
