import { FC } from 'react'

import styles from './Headline.module.css'

type HeadlineProps = {
  highlight: string
  rest?: string
}

const Headline: FC<HeadlineProps> = ({ highlight, rest = '' }): JSX.Element => {
  return (
    <div className={styles.Headline}>
      <span className="pr-1 text-green-800">{highlight}</span> {rest}
    </div>
  )
}

export default Headline
