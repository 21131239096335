import { FC } from 'react'
import Headline from 'components/Headline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const Resume: FC = (): JSX.Element => {
  return (
    <main>
      <Headline highlight="R" rest="esume" />
      <section className="flex flex-wrap">
        <div className="w-1/2">
          <h2 className="flex items-center uppercase text-text-secondary">
            <FontAwesomeIcon
              className="mr-3 text-3xl text-green-800"
              icon={solid('briefcase')}
            />
            Experience
          </h2>
          <div className="py-4">
            <p>
              June 2020 - August 2022
              <br />
              Xogito Group, Inc
            </p>
          </div>
          <div className="py-4">
            <p>
              March 2020 - November 2020
              <br />
              ThinkSoftware
            </p>
          </div>
          <div className="py-4">
            <p>
              February 2012 - February 2020
              <br />
              SENDMOMENTS
            </p>
          </div>
          <div className="py-4">
            <p>
              Jun 2015 - March 2020
              <br />
              Content Analytics inc
            </p>
          </div>
          <div className="py-4">
            <p>
              January 2011 - April 2011
              <br />
              cosmothemes
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <h2 className="flex items-center uppercase text-text-secondary">
            <FontAwesomeIcon
              className="mr-3 text-3xl text-green-800"
              icon={solid('building-columns')}
            />
            Education
          </h2>
          <div className="py-4">
            <p>
              2008 - 2011 Free International University of Moldova International
              Economic Relations http://ulim.md/en/
            </p>
          </div>
          <div className="py-4">
            <p>
              2004 - 2008 College of Informatics in Chisinau Programming and
              analysis programs https://ceiti.md/language/en/main/
            </p>
          </div>
        </div>
      </section>
      <Headline highlight="My" rest="Skills" />
      <section className="flex flex-wrap">
        <div className="w-1/2">
          <h2 className="flex items-center uppercase text-text-secondary">
            <FontAwesomeIcon
              className="mr-3 text-3xl text-green-800"
              icon={solid('code')}
            />
            Coding
          </h2>
        </div>
        <div className="w-1/2">
          <h2 className="flex items-center uppercase text-text-secondary">
            <FontAwesomeIcon
              className="mr-3 text-3xl text-green-800"
              icon={solid('list')}
            />
            Knowledge
          </h2>
        </div>
      </section>
    </main>
  )
}

export default Resume
