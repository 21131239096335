import { FunctionComponent } from 'react'
import Contacts from './Contacts'
import Home from './Home'
import Resume from './Resume'

type RouteType = {
  name: string
  path: string
  component: FunctionComponent
}

export const routesConfig: RouteType[] = [
  {
    name: 'home',
    path: '/',
    component: Home,
  },
  {
    name: 'resume',
    path: 'resume',
    component: Resume,
  },
  {
    name: 'contacts',
    path: 'contacts',
    component: Contacts,
  },
]
